<template>
  <CDataTable
    :items="computedItems"
    :fields="fields"
    hover
    :items-per-page-select="{label: 'Einträge pro Seite'}"
    :items-per-page="25"
    column-filter
    sorter
    pagination
  >
    <template #no-items-view><NoData/></template>

    <template #sollstunden="{item}">
      <td v-if="item.sollstunden">
        {{item.sollstunden}}
      </td>
      <td v-else>-</td>
    </template>

    <template #last_login="{item}">
      <td v-if="item.last_login">
        {{item.last_login|moment('YYYY-MM-DD - HH:mm')}} Uhr
      </td>
      <td v-else>-</td>
    </template>

    <template #actionbar="{item}">
      <td nowrap="" width="1%">
        <CButton
          color="primary"
          variant="outline"
          square
          size="sm"
          v-c-tooltip="{content: 'Editieren', placement:'left'}"
          v-on:click="editEntry(item.mitarbeiterid)"
        >
          <CIcon name="cil-highlighter"/>
        </CButton>
        <CButton
          color="success"
          variant="outline"
          size="sm"
          class="ml-2"
          v-c-tooltip="{content: 'Inaktiv setzen', placement:'left'}"
          v-on:click="toggleAktiv(item.mitarbeiterid)"
          v-if="item.aktiv"
        >
          <CIcon name="cil-lock-unlocked"/>
        </CButton>
        <CButton
          color="danger"
          variant="outline"
          size="sm"
          class="ml-2"
          v-c-tooltip="{content: 'Aktiv setzen', placement:'left'}"
          v-on:click="toggleAktiv(item.mitarbeiterid)"
          v-if="!item.aktiv"
        >
          <CIcon name="cil-lock-locked"/>
        </CButton>
        <CButton
          color="primary"
          variant="outline"
          square
          size="sm"
          class="ml-2"
          v-c-tooltip.hover.click="{content: 'Passwort ändern', placement:'left'}"
          v-on:click="passwordChange(item.mitarbeiterid)"
        >
          <CIcon name="cil-https"/>
        </CButton>
        <CButton
          color="danger"
          variant="outline"
          size="sm"
          class="ml-2"
          v-c-tooltip="{content: 'Löschen', placement:'left'}"
          v-on:click="deleteEntry(item.mitarbeiterid)"
          v-if="!item.aktiv"
        >
          <CIcon name="cil-trash"/>
        </CButton>
      </td>
    </template>

  </CDataTable>
</template>

<script>
import Vue from 'vue'
import NoData from '@/components/NoData'

export default {
  name: 'MitarbeiterTable',
  props: {
    mitarbeiter: Array,
    value: String
  },
  components: {
    NoData
  },
  data () {
    return {
      fields: [
        {
          key: 'name',
          label: 'Name'
        },
        {
          key: 'email',
          label: 'E-Mail'
        },
        {
          key: 'sollstunden',
          label: 'Sollstunden'
        },
        {
          key: 'roles',
          label: 'Rollen'
        },
        {
          key: 'titel',
          label: 'Titel'
        },
        {
          key: 'last_login',
          label: 'Letzter Login'
        },
        {
          key: 'actionbar',
          label: '',
          _style: 'width:1%',
          sorter: false,
          filter: false
        }
      ]
    }
  },
  mounted () {
  },
  computed: {
    computedItems () {
      if (this.mitarbeiter) {
        if (this.mitarbeiter.length > 0) {
          return this.mitarbeiter.map(mtrb => {
            const result = {
              mitarbeiterid: mtrb.mitarbeiterid,
              aktiv: mtrb.aktiv,
              name: mtrb.name,
              email: mtrb.email,
              sollstunden: mtrb.sollstunden || '-',
              roles: mtrb.roles,
              titel: mtrb.titel,
              last_login: mtrb.last_login
            }
            // Ersetzen der Rollen
            if (result.roles) { result.roles = result.roles[0] }
            if (result.roles === 'ROLE_SUPER_ADMIN') { result.roles = 'Admininstrator*' }
            if (result.roles === 'ROLE_ADMIN') { result.roles = 'Admininstrator' }
            if (result.roles === 'ROLE_REPORT') { result.roles = 'Report/Verwaltung' }
            if (result.roles === 'ROLE_VERWALTUNG') { result.roles = 'Verwaltung' }
            if (result.roles === 'ROLE_TEAM') { result.roles = 'Mitarbeiter/Monteure' }
            if (result.roles === 'ROLE_KALENDER') { result.roles = 'Kalender' }
            // Ersetzen der Titel
            if (result.titel === '10') { result.titel = 'Helfer' }
            if (result.titel === '20') { result.titel = 'Programmierer' }
            if (result.titel === '30') { result.titel = 'Monteur' }
            if (result.titel === '40') { result.titel = 'Obermonteur' }
            if (result.titel === '50') { result.titel = 'Kundendiensttechniker' }
            if (result.titel === '60') { result.titel = 'Meister' }
            if (result.titel === '70') { result.titel = 'Systemtechniker' }

            return result
          })
        } else {
          return []
        }
      } else {
        return []
      }
    }
  },
  methods: {
    editEntry (mitarbeiterid) {
      this.$router.push({ path: `/mitarbeiter/editieren/${mitarbeiterid}` })
    },
    toggleAktiv: function (mitarbeiterid) {
      const self = this
      const options = {
        okText: 'Ja',
        cancelText: 'Nein'
      }
      self.$dialog
        .confirm('Den Status des Mitarbeiters wirklich ändern?', options)
        .then((dialog) => {
          Vue.axios.put('/mitarbeiter/toggle/aktiv/' + mitarbeiterid)
            .then(function (response) {
              self.$snotify.success('Der Status des Mitarbeiters wurde geändert.', {
                position: 'rightTop',
                timeout: 4000
              })
              self.$emit('input', mitarbeiterid)
            })
        })
        .catch(() => {
          // Do Nothing
        })
    },
    deleteEntry: function (mitarbeiterid) {
      const self = this
      const options = {
        okText: 'Ja',
        cancelText: 'Nein'
      }
      this.$dialog
        .confirm('Den Mitarbeiter wirklich löschen?', options)
        .then((dialog) => {
          Vue.axios.delete('/mitarbeiter/delete/' + mitarbeiterid)
            .then((response) => {
              self.$snotify.error('Der Mitarbeiter wurde gelöscht.', {
                position: 'rightTop',
                timeout: 4000
              })
              self.$emit('input', mitarbeiterid)
            })
        })
        .catch(() => {
          // Do Nothing
        })
    },
    passwordChange (mitarbeiterid) {
      this.$router.push({ path: `/mitarbeiter/passwort/${mitarbeiterid}` })
    }
  }
}
</script>
